import React from "react";
import "../css/Popup.css";

const Policies = (props) => {
  return (
    <div className="coming-soon card">
      <h1>Terms Of Service</h1>
      <h3>
        By clicking the Continue button below, you agree to the following terms
        of service.
      </h3>
      <br />
      <p className="left-justify">
        <spam className="bold">Cancellation Policy: </spam>Clients must provide
        at least 24 hours' notice to cancel or reschedule an appointment.
        Cancellations or no-shows within this time will result in a fee of 100%
        of the scheduled service.
      </p>
      <p className="left-justify">
        <span className="bold">Late Arrivals: </span>If you arrive late, your
        session may be shortened to ensure the next appointment begins on time.
        The full session fee will still apply.
      </p>
      <p className="left-justify">
        <span className="bold">Inappropriate Behavior: </span>Touch Massage LLC
        enforces a zero-tolerance policy for any inappropriate behavior,
        including but not limited to inappropriate language, actions, or
        requests. Any violation will result in immediate termination of the
        session and possible refusal of future services.
      </p>
      <br />

      <button className="btn">
        <a href="/">Cancel</a>
      </button>
      <button className="btn">
        <a href="https://go.booker.com/location/TouchMassageL53735">Continue</a>
      </button>
    </div>
  );
};

export default Policies;
