import React, { useReducer } from "react";
import { ServiceContext } from "../Contexts.js";
import serviceReducer from './ServicesReducer.js';

const ServiceState = props => {
    const initialState = {
        services: [
            {
                title: 'TMJ Massage',
                description: 'A focused treatment targeting the neck, jaw & face to relieve pain and tension from Temporomandibular Joint (TMJ) dysfunction. Ideal for reducing jaw pain, headaches & tension caused by clenching, grinding &/or stress.',
                price30: null,
                price60: 90,
                price90: 135,
                price120: 180,
            },
            {
                title: 'Swedish',
                description: 'Relaxing, gentle massage that promotes overall wellness and reduces stress.',
                price30: 45,
                price60: 90,
                price90: 135,
                price120: 180,
            },
            {
                title: 'Deep Tissue',
                description: 'Focused on relieving tension in deeper muscle layers for chronic pain relief.',
                price30: 45,
                price60: 90,
                price90: 135,
                price120: 180,
            },
            {
                title: 'Prenatal',
                description: 'Tailored for expectant mothers to ease discomfort and promote relaxation.',
                price30: 45,
                price60: 90,
                price90: 135,
                price120: 180,
            },
            {
                title: 'Lymphatic Wellness',
                description: 'Gentle massage to encourage lymphatic drainage and boost the immune system.',
                price30: 45,
                price60: 90,
                price90: 135,
                price120: 180,
            },
            {
                title: 'Sports',
                description: 'Targeted therapy for athletes, improving flexibility, performance, and recovery.',
                price30: 45,
                price60: 90,
                price90: 135,
                price120: 180,
            },
            {
                title: 'Stretching',
                description: 'Assisted stretching to enhance flexibility, mobility, and muscle recovery.',
                price30: 45,
                price60: 90,
                price90: 135,
                price120: 180,
            },
            {
                title: 'Reiki',
                description: 'Energy healing technique to balance the body\'s energy and promote emotional well-being.',
                price30: 45,
                price60: 90,
                price90: null,
                price120: null,
            },
            {
                title: 'Reiki + Massage',
                description: 'Combines the healing touch of massage with the balancing effects of Reiki.',
                price30: 45,
                price60: 90,
                price90: 135,
                price120: 180,
            },
            {
                title: 'Couples Massage',
                description: 'Allows partners to enjoy side-by-side massages in a peaceful environment.',
                price30: 90,
                price60: 180,
                price90: 270,
                price120: 360,
            },
            {
                title: 'Therapeutic Massage',
                description: 'A targeted massage to relieve tension, reduce pain, and restore balance, tailored to your unique needs for enhanced well-being.',
                price30: 45,
                price60: 90,
                price90: 135,
                price120: 180,
            },
            {
                title: 'Post-Op Lymphatic Massage',
                description: 'Reduce swelling, prevent fibrosis, and speed up recovery with gentle, targeted techniques. Our experienced therapists support your healing journey with personalized care in a tranquil, supportive environment.',
                price30: 65,
                price60: 110,
                price90: 155,
                price120: 200,
            },
            {
                title: 'Pre-Op Lymphatic Massage',
                description: 'Prepare your body for surgery with gentle lymphatic techniques that reduce fluid retention, boost circulation, and support your immune system. Personalized care to promote optimal surgical outcomes.',
                price30: 45,
                price60: 90,
                price90: 135,
                price120: 180,
            },
            {
                title: 'Sugar Scrub',
                description: 'A full body scrub using relaxing massage techniques to relax, exfoliate, remove impurities, and brighten the skin. ',
                price30: null,
                price60: 110,
                price90: 165,
                price120: 220,
            }
        ],
        addons: [
            {
                title: 'CBD',
                description: 'Infuses massage with CBD oil to reduce inflammation and promote deep relaxation.',
                price: 20,
            },
            {
                title: 'Steam Eye Mask',
                description: 'Enhance your massage with our unscented steam eye mask, offering 30–40 minutes of soothing warmth to relieve stress, eye fatigue, dry eyes, and more. This disposable mask promotes deep relaxation, reduces dark circles and fine lines, and complements your wellness experience. Note: Not for use with contact lenses.',
                price: 10,
            },
            {
                title: 'Cooling Muscle Balm',
                description: 'Cooling therapy to relieve muscle pain and soreness.',
                price: 15,
            },
            {
                title: 'Cupping',
                description: 'Traditional therapy using suction cups to improve circulation and relieve muscle tension.',
                price: 20,
            },
            {
                title: 'Hot Stones',
                description: 'Warm stones are used to deeply relax muscles and enhance the massage experience.',
                price: 15,
            },
            {
                title: 'Hyaluronic Acid Eye Mask',
                description: 'Enhance your massage with our Hyaluronic Acid Eye Mask. It hydrates, reduces puffiness, and helps diminish dark circles, all while you relax and unwind during your massage. ',
                price: 10,
            },
            {
                title: 'Magnesium',
                description: 'Applied during massage to soothe muscle aches/spasms and improve skin health. Upon initial use, some people experience some mild tingling, similar to the sensation of salty ocean water which quickly subsides.',
                price: 15,
            },
            {
                title: 'Red Light Therapy',
                description: 'Stimulates collagen production, reduces inflammation, and promotes skin healing and cellular regeneration.',
                price: 15,
            },
            {
                title: 'Sugar Scrub for Hands & Feet',
                description: 'This sugar scrub for hands and feet exfoliates the skin, enhances circulation, and provides deep hydration, leaving the skin soft, rejuvenated, and nourished.',
                price: 15,
            },
            {
                title: 'Scalp Massage',
                description: 'Relaxing scalp treatment using nourishing coconut oil for hydration.',
                price: 10,
            },
            {
                title: 'Scalp Massage + Rosemary',
                description: 'Revitalizing treatment that hydrates, enhances circulation, and promotes hair health.',
                price: 15,
            },
            {
                title: 'Scalp Massage + Peppermint',
                description: 'Refreshing massage that soothes tension, stimulates blood flow, and cools the scalp.',
                price: 15,
            },
            {
                title: 'Hot/Cold Contrast Therapy',
                description: 'Alternating heat and cold applications to reduce inflammation, ease pain, and accelerate recovery.',
                price: 15,
            },
            {
                title: 'Cold Stone Facial Massage',
                description: 'This cooling treatment uses chilled stones to calm inflammation, reduce puffiness, and tighten the skin, leaving your face feeling refreshed and revitalized.',
                price: 10,
            },
            {
                title: 'Facial Cupping',
                description: 'Facial cupping gently lifts the skin with small cups to increase blood flow, stimulate collagen production, and promote a youthful, smooth appearance.',
                price: 10,
            },
            {
                title: 'Facial Gua Sha',
                description: 'A more targeted version of gua sha, this treatment focuses on facial contours to release facial tension, improve circulation, and enhance the natural radiance of your skin.',
                price: 10,
            },
            {
                title: 'Facial Red Light Therapy',
                description: 'Red light therapy utilizes specific wavelengths of light to penetrate deep into the skin, stimulating collagen production, reducing fine lines, and improving skin tone and texture for a radiant glow.',
                price: 10,
            },
            {
                title: 'Aromatherapy',
                description: null,
                price: 5,
                options: [
                    {
                        title: 'Lavender',
                        description: 'Soothes the nervous system, reduces stress, and supports restful sleep with its calming floral notes.',
                    },
                    {
                        title: 'Eucalyptus',
                        description: 'Clears mental fog, supports respiratory health, and revitalizes tired muscles with its crisp, cooling scent.',
                    },
                    {
                        title: 'Peppermint',
                        description: 'Sharpens focus, relieves headaches, and cools the body with its crisp, refreshing effect.',
                    },
                    {
                        title: 'Lemongrass',
                        description: 'Soothes muscle tension, encourages circulation, and refreshes the spirit with its bold, zesty aroma.',
                    },
                    {
                        title: 'Clary Sage',
                        description: 'Balances emotions, promotes relaxation, and eases stress with its soothing, herbal fragrance.'
                    },
                    {
                        title: 'Frankincense',
                        description: 'Promotes inner peace, supports healthy skin, and enhances focus with its warm, grounding essence.',
                    },
                    {
                        title: 'Grapefruit',
                        description: 'Helps regulate blood pressure, boosts metabolism, and uplifts mood with its bright, energizing aroma.',
                    },
                    {
                        title: 'Lemon',
                        description: 'Aids in detoxification, balances pH levels, and invigorates energy with its fresh, clean citrus essence. ',
                    },
                    {
                        title: 'Rosemary',
                        description: 'Stimulates hair growth, boosts circulation, and enhances focus with its invigorating herbal scent.',
                    },
                    {
                        title: 'Sweet Orange',
                        description: 'Uplifts mood, strengthens the immune system, and fosters positivity with its cheerful, sweet fragrance.',
                    }
                ],
            },
        ]
    };

    const [state, dispatch] = useReducer(serviceReducer, initialState);

    // Load Services
    const loadServices = async () => {
        await dispatch({
            type: 'SERVICES_LOADED',
            payload: initialState.services,
        });
    };

    // Load Addons
    const loadAddons = async () => {
        await dispatch({
            type: 'ADDONS_LOADED',
            payload: initialState.addons,
        });
    };

    return (
        <ServiceContext.Provider
            value={{
                services: state.services,
                loadServices,
                addons: state.addons,
                loadAddons,
            }}>
            {props.children}
        </ServiceContext.Provider>
    );
};

export default ServiceState;

