import React from "react";
import "../css/Home.css";
import Popup from "./Popup";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Touch Massage Therapy</title>
        <link rel="canonical" href="https://touch-massagetherapy.com/" />
      </Helmet>

      <Popup trigger={false}>
        <h1>Give the gift of relaxation this season!</h1>
        <p>
          Enjoy $20 off a 90-minute massage with code GIFT20.
          <br />
          The perfect way to show you care.
          <br />
          Limited-time offer!
        </p>
      </Popup>

      <div className="coming-soon card">
        <img
          src={require("../images/logo.webp")}
          alt="Touch Massage Therapy Logo"
          fetchpriority="high"
        />
        <br />
        <br />
        <p>
          At Touch Massage, we offer a variety of massage techniques so you are
          sure to find the perfect one to suit your needs. Our easy to use
          scheduling system ensures convenience for our clients. Unlike spas
          that offer saunas and facials, we simply specialize in the art of
          massage. Because when you're seeking{" "}
          <span className="fancy-font standout-text ">relaxation, </span>
          <span className="fancy-font standout-text">rejuvenation </span>, or
          <span className="fancy-font standout-text"> relief</span> from
          tension, nothing quite compares to the healing touch of a massage from
          a skilled therapist.
        </p>
        <br />
        <h1 className="fancy-font big-text-1">
          <a href="/policies">Book Now!!</a>
        </h1>
      </div>
    </>
  );
};

export default Home;
