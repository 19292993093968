import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Import contexts
import TeamState from './context/team/TeamState';
import ServiceState from './context/services/ServicesState';

// Import Components
import Navbar from './components/layout/navbar';
import Home from './components/home';
import Footer from './components/layout/footer';
import TeamMemberLiist from './components/team/teamList';
import Services from './components/services/serviceList';
import JobseekerForm from './components/jobs/JobseekerForm';
import WhatsNew from './components/WhatsNew';
import Testimonials from './components/Testimonials';
import Policies from './components/Policies';
import Facials from './components/services/Facials';
import Addons from './components/services/Addons';

// Import CSS
import './css/App.css';

const App = () => {
  return (
    <TeamState>
      <ServiceState>
        <Router>
          <Navbar />
          <div className='main-content'>
            <Routes>
              <Route index element={<Home />} />
              <Route exact path='/team' element={<TeamMemberLiist />} />
              <Route exact path='/services' element={<Services />} />
              <Route exact path='/facials' element={<Facials />} />
              <Route exact path='/addons' element={<Addons />} />
              <Route exact path='/jobs' element={<JobseekerForm />} />
              <Route exact path='/whatsnew' element={<WhatsNew />} />
              <Route exact path='/testimonials' element={<Testimonials />} />
              <Route exact path='/policies' element={<Policies />} />
              <Route path='*' element={<Home />} />
            </Routes>
          </div>
          <Footer />
        </Router>
      </ServiceState>
    </TeamState>
  );
}

export default App;
