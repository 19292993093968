import React from "react";
import { Helmet } from "react-helmet";

const Facials = () => {
  return (
    <>
      {" "}
      <Helmet>
        <title>Facials</title>
        <link
          rel="canonical"
          href="https://touch-massagetherapy.com/services"
        />
      </Helmet>
      <div className="container">
        <h1 className="fancy-font center-text glow">Honey Glow Facial</h1>
        <br />
        <h2>$75 | 30 minutes</h2>
        <p>
          Glow from within with our Honey Glow Facial, designed to rejuvenate
          and nourish all skin types. This holistic treatment uses premium
          natural ingredients to cleanse, exfoliate, and restore your skin’s
          radiance. Enjoy a double cleanse, Manuka honey exfoliation, a
          detoxifying tulsi mask, and deeply hydrating moisturizers for a quick
          refresh and a glowing complexion.
        </p>
        <br />
        <h2>Massage + Honey Glow Facial Packages</h2>
        <br />
        <ul>
          <li>
            <h4>90 Minutes Total | $150</h4>
            <p>
              Enjoy a 60-minute therapeutic massage tailored to your needs,
              followed by a 30-minute Honey Glow Facial to leave your body
              relaxed and your skin glowing.
            </p>
          </li>
          <li>
            <h4>120 Minutes Total | $195</h4>
            <p>
              Combine a 90-minute therapeutic massage for full-body tension
              relief with our 30-minute Honey Glow Facial for the ultimate
              self-care experience.
            </p>
          </li>
          <li>
            <h4>150 Minutes Total | $240</h4>
            <p>
              Indulge in a 120-minute therapeutic massage for deep relaxation
              and rejuvenation, paired with our luxurious 30-minute Honey Glow
              Facial to refresh and hydrate your skin.
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Facials;
