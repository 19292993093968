import React from "react";
import AddonItem from "./AddonItem";
import { ServiceContext } from "../../context/Contexts";
import "../../css/Services.css"; // Import the CSS file for styling
import { Helmet } from "react-helmet";
import { useContext, useEffect } from "react";

const Addons = () => {
  const serviceContext = useContext(ServiceContext); // Use the ServiceContext
  const { addons, loadAddons } = serviceContext; // Destructure services and loadServices from context

  useEffect(() => {
    loadAddons(); // Load addons when the component mounts

    // eslint-disable-next-line
  }, []); // Empty dependency array to run only once

  return (
    <>
      <Helmet>
        <title>Services</title>
        <link
          rel="canonical"
          href="https://touch-massagetherapy.com/services"
        />
      </Helmet>

      <h1 className="center-text glow fancy-font big-text-2">Add-Ons</h1>
      <div className="services-container">
        {addons
          .sort((a, b) => (a.title > b.title ? 1 : -1)) // Sort addons by title
          .map((item) => (
            <AddonItem key={item.title} item={item} /> // Assuming each addon item has a unique 'id'
          ))}
      </div>
    </>
  );
};

export default Addons;
